import React, { useState } from 'react'

export default function ServiceTabs() {
  const printingPhotocopying = 
    <div>
    We recently started offering printing and photocopying in both black and colour. If you need something to be printed or photocopy some documents, we will help you with our LaserJet printers to provide some high quality prints.<br/><br/> 
    <strong>We only do letter-size printing (8.5 × 11).</strong>{' '}<span className='text-red-500'>We do not offer scanning, laminations, binding or make labels.</span>
    </div>
  const laserTonerRefill =
    <div>
      Similar to refilling ink cartridges, we also refill laser toners. We use the same kind of ink powder that an OEM uses to refill your laser toners, weigh it for full capacity and test it to make sure it works. If you have an original empty laser toner, visit our store to do a quick trade-in and receive a refilled one for cheaper than buying in retail. <br/><br/>
      Want to check if your toner can be refilled? Call us at (905) 799-7430 and be prepared to give the staff the toner model number.
    </div>
  const inkCartridgeRefill =
    <div>
      Our main service is refilling your ink cartridges to keep them printing for longer rather than a one-time use. We use our patented machinery, the R-III InkJet to steam, vacuum and refill your cartridges to help you get the maximum value out of them.<br/><br/> 
      Many cartridges can be refilled up to 10x, which means you’re saving a lot of money in the long-run before you have to buy a new replacement!
    </div>

  const [content, setContent] = useState(laserTonerRefill)
  const [activeButton, setActiveButton] = useState("laserTonerRefill");
  const [selectedTab, setSelectedTab] = useState('laserTonerRefill');

  // Event handler for when the user selects a different option
  const handleSelectChange = (event) => {
    setSelectedTab(event.target.value);
    handleClick(event.target.value)
  };

  // Handler to set active button
  const handleBtnClick = (buttonName) => {
    setActiveButton(buttonName);
  };

  // Function to determine button classes
  const buttonClass = (buttonName) => {
    return buttonName === activeButton
      ? "shrink-0 p-3 text-sm font-medium text-sky-600 rounded-t-lg border border-gray-300 border-b-white"
      : "shrink-0 p-3 text-sm font-medium border border-transparent text-gray-500 hover:text-gray-700";
  };

  const handleClick = (text) => {
    if (text === "printingPhotocopying"){
      setContent(printingPhotocopying)
    }
    else if (text === "laserTonerRefill"){
      setContent(laserTonerRefill)
    }
    else if (text === "inkCartridgeRefill"){
      setContent(inkCartridgeRefill)
    }
    handleBtnClick(text)
  }

  return (
    <section id="services" className='flex flex-col justify-center w-[70%] my-16 mx-auto max-sm:w-[90%] xl:w-[50%]'>
      <span className="text-center text-3xl font-bold sm:text-4xl">Services</span>

      <div className="sm:hidden mt-4">
        <label htmlFor="Tab" className="sr-only">Tab</label>
        <select 
          id="Tab" 
          className="w-full rounded-md border-gray-200"
          value={selectedTab}
          onChange={handleSelectChange}
        >
          <option value="laserTonerRefill">Refilling Laser Toners & Trade-In</option>
          <option value="inkCartridgeRefill">Refilling Ink Cartridges</option>
          <option value="printingPhotocopying">Printing & Photocopying</option>
        </select>
      </div>

      <div className="hidden sm:block mt-4">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex gap-6 justify-center">
            <button
              name="lasertoners"
              onClick={() => handleClick("laserTonerRefill")}
              className={buttonClass("laserTonerRefill")}
            >
              Refilling Laser Toners & Trade-In
            </button>

            <button
              name="inkCartridgeRefill"
              onClick={() => handleClick("inkCartridgeRefill")}
              className={buttonClass("inkCartridgeRefill")}
            >
              Refilling Ink Cartridges
            </button>

            <button
              name="printingPhotocopying"
              onClick={() => handleClick("printingPhotocopying")}
              className={buttonClass("printingPhotocopying")}           
            >
              Printing & Photocopying
            </button>
          </nav>
        </div>
      </div>

      <div className='w-[80%] mx-auto mt-2 whitespace-pre-line'>
        <div className="text-gray-600">
          {content}
        </div>
      </div>

    </section>
  )
}
