import React, { useEffect, useState } from 'react'
import FAQAccordion from './components/FAQAccordion'
import QNA from './components/FAQ.json'

export default function FAQ() {
  const [accordions, setAccordions] = useState([]);

  useEffect(() => {
    const newAccordions = [...accordions]
    const temp = QNA["qna"]
    let i = 0;
    temp.forEach(data => {
        let element = <FAQAccordion key={i} question={data["question"]} answer={data["answer"]}/>
        newAccordions.push(element)
        i = i + 1
    })
    setAccordions(newAccordions)
  }, [])

  useEffect(() => { console.log(accordions)}, [accordions])

  return (
    <div id="faq" className="flex flex-col justify-center w-[70%] mx-auto max-sm:w-[90%] xl:w-[50%]">
        <span className="text-center text-3xl font-bold sm:text-4xl">FAQ</span>
        <div className="space-y-4 mt-4">
            {accordions}
        </div>
    </div>
    
  )
}
