import React from 'react'
import Announcement from './Announcement'
import IJSTitle from './assets/Title.png'

function Nav() {
  return (
    <div className="fixed top-0 z-50 w-full">
    <header className="bg-white max-lg:hidden">
      <div className="mx-auto flex h-16 max-w-screen-xl items-center gap-8 px-4 sm:px-6 lg:px-8">
        <a className="block text-teal-600" href="#">
          <span className="sr-only">Home</span>
          <img src={IJSTitle} alt="IJS PNG" className="h-8 w-auto"/>
        </a>

        <div className="flex flex-1 items-center justify-center md:justify-end">
          <nav aria-label="Global" className="hidden md:block">
            <ul className="flex items-center gap-6 text-sm">
              <li>
                <a className="text-gray-500 transition hover:text-gray-500/75" href="#"> Home </a>
              </li>

              <li>
                <a className="text-gray-500 transition hover:text-gray-500/75" href="#about"> About </a>
              </li>

              <li>
                <a className="text-gray-500 transition hover:text-gray-500/75" href="#shoppers"> New Location </a>
              </li>

              <li>
                <a className="text-gray-500 transition hover:text-gray-500/75" href="#services"> FAQ </a>
              </li>

              {/* <li>
                <a className="text-gray-500 transition hover:text-gray-500/75" href="#reviews"> Reviews </a>
              </li> */}
            </ul>
          </nav>
        </div>
      </div>
    </header>
    <Announcement />
    </div>
  );
}

export default Nav;
