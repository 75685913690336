import React from "react"

export default function Announcement() {
  return (
    <div className="bg-indigo-600 px-4 py-3 text-white">
      <p className="text-center text-sm font-medium">
        <strong className="underline">STORE OPEN:</strong> Visit us at Shoppers World Brampton at the Northeast end of the mall! Use Entrance 2 or 3
      </p>
    </div>
  )
}
