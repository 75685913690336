import React from 'react';
import ReactDOM from 'react-dom/client';
import Home from './blocks/Home'
import About from './blocks/About';
import FAQ from './blocks/FAQ';
import ServiceTabs from './blocks/ServiceTabs';
import GoogleReviews from './blocks/GoogleReviews';
import MainPopup from './blocks/MainPopup';
import ShoppersWorld from './blocks/ShoppersWorld';

export default function App() {
  return (
    <div>
        <Home />
        <About />
        <ShoppersWorld/>
        <ServiceTabs/>
        <FAQ />
        {/* <GoogleReviews/> */}
        <MainPopup />
    </div>
  )
}
