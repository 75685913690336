import React, { useState } from 'react'
import Popup from './components/Popup'

export default function Home() {
  const [visible, setVisible] = useState(false)

  const handleClick = () => {
    setVisible(!visible)
  }

  return (
    <section className="bg-gray-50">
        <div className="mx-auto max-w-screen-xl px-4 py-32 lg:flex lg:h-screen lg:items-center">
            <div className="mx-auto max-w-xl text-center">
            <h1 className="text-3xl font-extrabold sm:text-5xl">
                IJS Ink & Toner.{' '}
                <strong className="font-extrabold text-red-700 sm:block">Refill Ink, Save Money.</strong>
            </h1>

            <p className="mt-4 sm:text-xl/relaxed">
                IJS Ink & Toner is an ink refill business committed to helping customers get the maximum value out of their cartridges and toners for their printing needs. 
            </p>

            <div className="mt-8 flex flex-wrap justify-center gap-4">
                <a
                className="block w-full rounded bg-red-600 px-12 py-3 text-sm font-medium text-white shadow hover:bg-red-700 focus:outline-none focus:ring active:bg-red-500 sm:w-auto"
                onClick={() => handleClick()}
                >
                New Location!
                </a>

                <a
                className="block w-full rounded bg-black text-red-500 px-12 py-3 text-sm font-medium shadow hover:bg-slate-800 focus:outline-none focus:ring active:bg-slate-500 sm:w-auto"
                href="#about"
                >
                Learn More
                </a>

                <a
                className="block w-full rounded px-12 py-3 text-sm font-medium text-red-600 shadow hover:text-red-700 focus:outline-none focus:ring active:text-red-500 sm:w-auto"
                href="#services"
                >
                Questions?
                </a>
            </div>
            </div>
        </div>
        {visible ? <Popup visible={visible} handleClick={handleClick}/> : <></>}
    </section>
  )
}
