import React from 'react'
import IJSTitle from './assets/Title.png'

export default function Footer() {
  return (
    <div>
    <footer className="bg-gray-50 mt-16">
    <div className="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center sm:justify-between">

        <div className="text-gray-500 flex justify-center items-center gap-4 sm:justify-start">
            <img src={IJSTitle} alt="Printer SVG" className="h-8 w-auto"/>
            {/* <span className="">IJS Ink and Toner</span> */}
        </div>
  
        <p className="mt-4 text-center text-sm text-gray-500 lg:mt-0 lg:text-right">
          Copyright &copy; 1999. All rights reserved.
        </p>
      </div>
    </div>
  </footer>
  </div>
  )
}
