import React from "react"
import Store from "./assets/Bramalea.jpg"

export default function About() {
  return (
    <section id="about">
      <div className="mx-auto max-w-screen-xl px-4 py-4 sm:px-6 sm:py-12 lg:px-8 lg:py-8">
        <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
          <div className="relative h-64 overflow-hidden rounded-lg sm:h-80 lg:order-last lg:h-full">
            <img
              alt=""
              src={Store}
              className="absolute inset-0 h-full w-full object-cover"
            />
          </div>

          <div className="lg:py-16">
            <h2 className="text-3xl font-bold sm:text-4xl">About Us</h2>

            <p className="mt-4 text-gray-600 whitespace-pre-line">
              Since 1999, IJS Ink & Toner refills ink cartridges and laser
              toners to ensure customers get the maximum value out of their
              cartridges with{" "}
              <strong>multiple uses instead of one-time use</strong>. We{" "}
              <strong>use steam, vacuum and a weight system</strong> to ensure
              each cartridge is properly refilled to the full amount of ink as
              OEM (original equipment manufacturer).
              <br />
              <br />
              We will always test the cartridges and toners to make sure it
              works and meets our high quality standards.
              <br />
              <br />
            </p>
            <span className="font-bold text-gray-600">
              We are now open at Shoppers World! Visit us through Entrance 2 or 3 (near Rogers and Anderson College) to get an ink refill.{" "}
            </span>

            {/* <a
                href="#"
                className="mt-8 inline-block rounded bg-indigo-600 px-12 py-3 text-sm font-medium text-white transition hover:bg-indigo-700 focus:outline-none focus:ring focus:ring-yellow-400"
                >
                Get Started Today
                </a> */}
          </div>
        </div>
      </div>
    </section>
  )
}
